import Image from 'next/image';

import { Box } from '@chakra-ui/react';

import banner from '../../../../libs/images/hero area.png';

export function HomeBanner() {
  return (
    <Box
      width="100%"
      height="184px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="8px"
      bottom="0"
      background="var(--Gray-Scale-Gray300, #F5F5F7)"
      position="relative"
    >
      <Image src={banner} alt="banner" layout="fill" objectFit="cover" />
    </Box>
  );
}
