import Image from 'next/image';

import { Flex } from '@chakra-ui/react';

interface CategoryIconProps {
  icon: string;
  title: string;
}

export function CategoryIcon({ icon, title }: CategoryIconProps) {
  return (
    <Flex
      width="48px"
      alignItems="center"
      justifyContent="center"
      height="48px"
      borderRadius="16px"
      bg="#F5F5F7"
    >
      <Image src={icon} alt={title} />
    </Flex>
  );
}
