import { ReactNode } from 'react';

import { Button } from '@chakra-ui/react';

interface ButtonWrapperProps {
  onClick: () => void;
  children: ReactNode;
}

export function ButtonWrapper({ onClick, children }: ButtonWrapperProps) {
  return (
    <Button
      bg="none"
      height="fit-content"
      _hover={{ bg: 'none' }}
      padding="0"
      width="fit-content"
      px="16px"
      textAlign="center"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      justifySelf="center"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
