'use client';

import { useState } from 'react';

import { useRouter } from 'next/navigation';

import { Box } from '@chakra-ui/react';

import all_time from '../components/home/assets/24hours.svg';
import all from '../components/home/assets/all-menu.svg';
import bone from '../components/home/assets/bone.svg';
import cat_friendly from '../components/home/assets/cat-friendly.svg';
import dentist from '../components/home/assets/dentist.svg';
import eye from '../components/home/assets/eye.svg';
import first from '../components/home/assets/first.svg';
import second from '../components/home/assets/second.svg';
import skin from '../components/home/assets/skin.svg';
import third from '../components/home/assets/third.svg';
import video from '../components/home/assets/video-medical.svg';
import { HomeBanner } from '../components/home/HomeBanner';
import { HomeCategorySection } from '../components/home/HomeCategorySection';
import { HomeFooter } from '../components/home/HomeFooter';
import { HomeQuickMenu } from '../components/home/HomeQuickMenu';
import { HomeSearchBar } from '../components/home/HomeSearchBar';
import LocationButton from '../components/main/LocationButton';
import RequestLocationModal from '../components/main/RequestLocationModal';
import useResetAtoms from '../hooks/useResetAtoms';
import styles from './page.module.scss';

const categories = [
  {
    key: 'All',
    value: '전체보기',
    buttonContent: '전체보기',
    iconPath: all,
  },
  {
    key: '2',
    value: '치과',
    buttonContent: '치과',
    iconPath: dentist,
  },
  {
    key: '4',
    value: '피부과',
    buttonContent: '피부과',
    iconPath: skin,
  },
  {
    key: '1',
    value: '고양이',
    buttonContent: '고양이 친화',
    iconPath: cat_friendly,
  },
  {
    key: '3',
    value: '안과',
    buttonContent: '안과',
    iconPath: eye,
  },
  {
    key: '5',
    value: '정형외과',
    buttonContent: '정형외과',
    iconPath: bone,
  },
  {
    key: '6',
    value: '영상의학과',
    buttonContent: '영상의학과',
    iconPath: video,
  },
];

const scales = [
  {
    key: 'ALWAYS',
    value: '24시 병원',
    badgeText: '24시 병원',
    buttonContent: '24시 동물병원',
    iconPath: all_time,
  },
  {
    key: 'SMALL',
    value: '1차 병원',
    badgeText: '동네병원',
    buttonContent: '1차 동물병원',
    iconPath: first,
  },
  {
    key: 'MEDIUM',
    value: '2차 병원',
    badgeText: '동네병원보다 큰 병원',
    buttonContent: '2차 동물병원',
    iconPath: second,
  },
  {
    key: 'LARGE',
    value: '3차 병원',
    badgeText: '대학병원',
    buttonContent: '3차 동물병원',
    iconPath: third,
  },
];

const filters = [
  {
    key: 'regions',
    value: '지역',
  },
  {
    key: 'categories',
    value: '진료',
  },
  {
    key: 'scales',
    value: '규모',
  },
];

export default function Home() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useResetAtoms();

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleModalOpen = () => {
    // if (permission) {
    //   requestLocation();
    //   return;
    // }
    setIsOpen(true);
  };

  const handleModalConfirm = () => {
    // setSearchLocation(undefined);
    // requestLocation();
    handleModalClose();
  };

  const handleAddress = () => {
    handleModalClose();
    router.push(`/address`);
  };

  return (
    <div className={styles.container}>
      <Box position="relative" width="100%">
        <LocationButton onClick={handleModalOpen} />
        <HomeBanner />
        <HomeSearchBar />
        <Box mt="32px" mb="200px" px="16px">
          <HomeQuickMenu filters={filters[0]} />
          <HomeCategorySection
            category_title="진료별"
            type={categories}
            filters={filters[1]}
          />
          <HomeCategorySection
            category_title="규모별"
            type={scales}
            filters={filters[2]}
          />
        </Box>
        <HomeFooter />
        <RequestLocationModal
          isOpen={isOpen}
          onClose={handleModalClose}
          onClickConfirm={handleAddress}
          onClickCancel={handleModalConfirm}
        />
      </Box>
    </div>
  );
}
