import { Box, Flex, Image, Text } from '@chakra-ui/react';
import termsOfUseIcon from '@my-furry-family/images/terms_of_use.svg';
import dividerIcon from '@my-furry-family/images/divider.svg';
import myFurryFriendIcon from '@my-furry-family/images/my_furry_friend.svg';
import * as urlConstants from '../../constants/url';

export function HomeFooter() {
  return (
    <Box
      position="absolute"
      width="100%"
      padding="20px 16px"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      gap="8px"
      bottom="0"
      background="var(--Gray-Scale-Gray300, #F5F5F7)"
    >
      <Flex alignItems="center" gap="2.353px">
        <Image src={termsOfUseIcon.src} alt="" />
        <Image src={myFurryFriendIcon.src} alt="" />
      </Flex>
      <Flex alignItems="center" gap="8px">
        <Flex
          // width="60px"
          height="28px"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'LINKING_OPEN_URL',
                url: urlConstants.INTRODUCTION_MY_FURRY_FRITEND_URL,
              }),
            );
          }}
        >
          <Text
            color="var(--Gray-Scale-Gray600, #9A9AA1)"
            fontFamily="Pretendard"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            내새꾸 소개
          </Text>
        </Flex>
        <Image src={dividerIcon.src} alt="" />
        <Flex
          // width="60px"
          height="28px"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'LINKING_OPEN_URL',
                url: urlConstants.TERMS_OF_USE_URL,
              }),
            );
          }}
        >
          <Text
            color="var(--Gray-Scale-Gray600, #9A9AA1)"
            fontFamily="Pretendard"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            서비스 이용약관
          </Text>
        </Flex>
        <Image src={dividerIcon.src} alt="" />
        <Flex
          // width="60px"
          height="28px"
          justifyContent="center"
          alignItems="center"
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'LINKING_OPEN_URL',
                url: urlConstants.PRIVACY_POLICY_URL,
              }),
            );
          }}
        >
          <Text
            color="var(--Gray-Scale-Gray600, #9A9AA1)"
            fontFamily="Pretendard"
            fontSize="10px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            개인정보처리방침
          </Text>
        </Flex>
      </Flex>
      <Flex gap={13}>
        <Text
          color="var(--Gray-Scale-Gray600, #9A9AA1)"
          fontFamily="Pretendard"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          사업자 : 젤리(Jelly)
        </Text>
        <Image src={dividerIcon.src} alt="" />
        <Text
          color="var(--Gray-Scale-Gray600, #9A9AA1)"
          fontFamily="Pretendard"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          대표자 : 김수민
        </Text>
      </Flex>
      <Flex>
        <Text
          color="var(--Gray-Scale-Gray600, #9A9AA1)"
          fontFamily="Pretendard"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          사업자등록번호 : 333-14-02467
        </Text>
      </Flex>
      <Flex>
        <Text
          color="var(--Gray-Scale-Gray600, #9A9AA1)"
          fontFamily="Pretendard"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          주소 : 서울시 동작구 상도로 55길 8, <br />
          <Text marginLeft={25}>
            챌린지스테이션 2층 공유형 입주실(상도동, 창의키움관)
          </Text>
        </Text>
      </Flex>
      <Flex>
        <Text
          color="var(--Gray-Scale-Gray600, #9A9AA1)"
          fontFamily="Pretendard"
          fontSize="10px"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
        >
          이용 / 제휴 문의 : jelly240701@gmail.com
        </Text>
      </Flex>
    </Box>
  );
}
