import { useRouter } from 'next/navigation';

import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';

export function HomeSearchBar() {
  const router = useRouter();
  const handleSearch = () => {
    router.push(`/search`);
  };
  return (
    <Box textAlign="center">
      <Button
        height="48px"
        borderRadius="10px"
        backgroundColor="white"
        width="92%"
        marginTop="-30px"
        justifyContent="flex-start"
        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.2)"
        leftIcon={<SearchIcon color="#9A9AA1" />}
        onClick={handleSearch}
      >
        <Box marginLeft="10px" color="#9a9aa1" fontSize="14px" fontWeight={400}>
          상세 필터를 적용하여 검색할 수 있어요.
        </Box>
      </Button>
    </Box>
  );
}
