import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/navigation';

import { Grid } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import map from '../assets/map.svg';
import menu from '../assets/menu.svg';
import { HomeQuickMenuItem } from './HomeQuickMenuItem';
import { searchHospitalConditions } from '../../../service/hospitalDetail';
import { search, selectedFilters } from '../../../store/search';

const REGIONS = [
  {
    key: 'NEAR',
    title: '반경 5km 이내',
    subtitle: '내 주변\n동물병원 찾기',
    icon: map,
  },
  {
    key: 'SEOUL',
    title: '서울시 전체 병원',
    subtitle: '서울시 내에\n위치한 동물병원',
    icon: menu,
  },
];

interface HomeQuickMenuProps {
  filters: {
    key: string;
    value: string;
  };
}

export function HomeQuickMenu({ filters }: HomeQuickMenuProps) {
  const router = useRouter();
  const [selectedFilter, setSelectedFilter] = useAtom(selectedFilters);
  const setSearchFilter = useSetAtom(search);
  const { data } = useQuery({
    queryKey: ['/animal-hospitals/search-conditions'],
    queryFn: searchHospitalConditions,
  });

  const handleButtonClick = (key: string) => {
    if (key === 'NEAR') {
      router.push('/search/result');
      return;
    }
    router.push('/search');
    setSearchFilter((prev) => ({
      ...prev,
      [filters.key]: data?.data.data[filters.key] || [],
    }));
    setSelectedFilter({
      key: filters.key,
      value: filters.value,
    });
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap="8px">
      {REGIONS.map((item) => (
        <div key={item.key}>
          <HomeQuickMenuItem
            title={item?.title}
            subtitle={item.subtitle}
            icon={item.icon}
            onClick={() => handleButtonClick(item.key)}
          />
        </div>
      ))}
    </Grid>
  );
}
