import React from 'react';

import Image from 'next/image';

import { Box, Flex } from '@chakra-ui/react';

interface HomeQuickMenuItemProps {
  title: string;
  subtitle: string;
  icon: string;
  onClick: () => void;
}

export function HomeQuickMenuItem({
  title,
  subtitle,
  icon,
  onClick,
}: HomeQuickMenuItemProps) {
  return (
    <Box
      width="100%"
      height="98px"
      padding="12px"
      background="#004DC8"
      borderRadius="12px"
      onClick={onClick}
    >
      <Flex height="100%" justifyContent="space-between">
        <Box>
          <Box color="#BFC8EF" fontSize="10px" fontWeight="500">
            {title}
          </Box>
          <Box color="white" fontSize="14px" fontWeight="600" marginTop="8px">
            {subtitle.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Flex alignItems="flex-end">
          <Image src={icon} alt="map" width={40} height={40} />
        </Flex>
      </Flex>
    </Box>
  );
}
