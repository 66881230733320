import { useAtom, useSetAtom } from 'jotai';
import { useRouter } from 'next/navigation';

import { ButtonWrapper } from './ButtonWrapper';
import { CategoryIcon } from './CategoryIcon';
import { CategoryTitle } from './CategoryTitle';
import { search, selectedFilters } from '../../../../store/search';

interface HomeCategoryItemProps {
  filters: {
    key: string;
    value: string;
  };
  item: {
    key: string;
    value: string;
    buttonContent: string;
    iconPath: string;
  };
}

export function HomeCategoryItem({ item, filters }: HomeCategoryItemProps) {
  const router = useRouter();
  const setSearchFilter = useSetAtom(search);
  const [selectedFilter, setSelectedFilter] = useAtom(selectedFilters);

  const handleButtonClick = () => {
    console.log(item, filters);
    if (item.key === 'All') {
      return router.push('/search');
    }
    router.push('/search');
    setSelectedFilter({
      key: filters.key,
      value: filters.value,
    });
    return setSearchFilter((prev) => ({
      ...prev,
      [filters.key]: [{ key: item.key, value: item.value || '' }],
    }));
  };

  return (
    <ButtonWrapper onClick={handleButtonClick}>
      <CategoryIcon icon={item.iconPath} title={item.value} />
      <CategoryTitle title={item.value} />
    </ButtonWrapper>
  );
}
