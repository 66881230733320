import { Box, Flex, Grid } from '@chakra-ui/react';

import { HomeCategoryItem } from './HomeCategoryItem';

interface HomeCategorySectionProps {
  filters: {
    key: string;
    value: string;
  };
  category_title: string;
  type: {
    key: string;
    value: string;
    buttonContent: string;
    iconPath: string;
  }[];
}

export function HomeCategorySection({
  category_title,
  type,
  filters,
}: HomeCategorySectionProps) {
  return (
    <Flex flexDirection="column" gap="16px" mt="32px">
      <Box color="#0F0F12" fontWeight={600}>
        {category_title}
      </Box>
      <Grid
        templateColumns="repeat(4, 1fr)"
        gap="16px"
        justifyContent="space-between"
      >
        {type.map((item) => (
          <HomeCategoryItem filters={filters} key={item.key} item={item} />
        ))}
      </Grid>
    </Flex>
  );
}
